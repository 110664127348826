import axios from 'axios';

import { SUCCESS, REQUEST, FAILURE } from 'app/shared/reducers/action-type.util';
import { stat } from 'fs';

export const ACTION_TYPES = {
    LIVIN_ACCESS_PERMISSION: 'livin/ACCESS_PERMISSION', // LIVIN_ACCESS_PERMISSION
    LIVIN_ACCESS_CHECK_USER: 'livin/ACCESS_CHECK_USER', // LIVIN_ACCESS_CHECK_USER
};

const initialState = {
    loading: false,
    code: '',
    message: '',
    actionType: '',
    reqStatus: 1,
    isTokenValid: false
};

export const API_REQUEST = 1;
export const API_SUCCESS = 2;
export const API_FAILURE = 3;

export type LivinState = Readonly<typeof initialState>

export default (state: LivinState = initialState, action): LivinState => {
    // const payload = action.payload;
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LIVIN_ACCESS_PERMISSION):
            return {
                ...state,
                loading: true,
                // livenessUrl: '',
                // livenessSignatureId: '',
                actionType: ACTION_TYPES.LIVIN_ACCESS_PERMISSION,
                reqStatus: API_REQUEST
            }
        case SUCCESS(ACTION_TYPES.LIVIN_ACCESS_PERMISSION):
            return {
                ...state,
                loading: false,
                code: action.payload?.data?.code,
                message: action.payload?.data?.message,
                actionType: ACTION_TYPES.LIVIN_ACCESS_PERMISSION,
                reqStatus: API_SUCCESS
            }
        case FAILURE(ACTION_TYPES.LIVIN_ACCESS_PERMISSION):
            return {
                ...state,
                loading: false,
                actionType: ACTION_TYPES.LIVIN_ACCESS_PERMISSION,
                reqStatus: API_FAILURE
            }

        case REQUEST(ACTION_TYPES.LIVIN_ACCESS_CHECK_USER):
            return {
                ...state,
                loading: true,
                actionType: ACTION_TYPES.LIVIN_ACCESS_CHECK_USER,
                reqStatus: API_REQUEST
            }
        case SUCCESS(ACTION_TYPES.LIVIN_ACCESS_CHECK_USER):
            return {
                ...state,
                loading: false,
                code: action.payload?.data?.code == undefined ? 200 : action.payload?.data?.code,
                message: action.payload?.data?.message == undefined ? "success" : action.payload?.data?.message,
                isTokenValid: action.payload?.data?.isTokenValid,
                actionType: ACTION_TYPES.LIVIN_ACCESS_CHECK_USER,
                reqStatus: API_SUCCESS
            }
        case FAILURE(ACTION_TYPES.LIVIN_ACCESS_CHECK_USER):
            return {
                ...state,
                loading: false,
                actionType: ACTION_TYPES.LIVIN_ACCESS_CHECK_USER,
                reqStatus: API_FAILURE
            }
        default:
            return state;

    }
}
