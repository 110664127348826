import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import {
    Button,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    Row,
    Spinner,
} from 'reactstrap';
import {
    // checkSpoofing,
    // performOcr,
    // livenessDetectionToken,
    // livenessDetectionResult,
    // livenessDetectionValidate,
    // livenessDetectionReset,
    grantAccessLivinAccessPermission,
} from 'app/api/revamp-api-livin';
import { ACTION_TYPES, API_SUCCESS } from 'app/shared/reducers/accesspermissionlivin';
import EndPoint from 'app/shared/util/api-constant';
import { useFormik } from 'formik';
import { Storage } from 'react-jhipster';
import Axios from 'axios';
import PageConstUtil from 'app/shared/util/page-constants';
import SuccessIllustration from 'app/component/success-illustration';
import FailedIllustration from 'app/component/failed-illustration';
import DarkPurpleButton from 'app/shared/layout/my-button/dark-purple-button';
import NavBackIcon from 'app/component/nav-back-icon';
import useWindowDimensions from 'app/shared/util/screen-dimension';
import LANG from 'app/shared/util/lang-constant';
import OtpInput from "react-otp-input";
import decryptJson from 'app/shared/util/security-decrypt';
import MyEncrypt from 'app/shared/util/security-encrypt';
import MySecKey from 'app/shared/util/security-key';
import { dateIsValid, toDateHots, toStringHots } from "app/shared/util/date-utils";
import HeaderForm from 'app/shared/layout/header-form/header-form';
import Select, { useStateManager } from 'react-select';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';


interface StoreBioFormProps {
    userId: any;
    ypId: [];
    investorCountry: any;
    investorCitizenship: any;
    investorFullName: any;
    investorCidNo: any;
    investorCidExp: any;
    investorKitasNo: any;
    investorKitasExp: any;
    investorPassNo: any;
    investorPassExp: any;
    investorBirthDate: any;
    investorBirthPlace: any;
    investorSex: any;
    investorMartialStatus: any;
    investorSpouseName: any;
    investorSpouseCidNo: any;
    investorSpouseBirthDate: any;
    investorSpouseCntcNo: any;
    investorMothersMaidenName: any;
    investorDependencyCount: any;
    investorSeperateAggreement: any;
    investorNPWPNumber: any;
    investorNPWPRegistrationDate: any;
    investorHomePhone: any;
    investorMobilePhone: any;
    investorEmail: any;
    // investorFax: any,
    investorEducationalBackground: any;

    investorCountryCode: any;
    investorCountryName: any;
    investorAddress: any;
    investorPrpsCd: any;
    investorPrpsNm: any;
    investorCityCd: any;
    investorCityNm: any;
    investorDistrictNm: any;
    investorUrbanCd: any;
    investorDistrictCd: any;
    investorUrbanNm: any;

    investorOtherCountryCode: any;
    investorOtherCountryName: any;
    investorOtherAddress: any;
    investorOtherPrpsCd: any;
    investorOtherPrpsNm: any;
    investorOtherCityCd: any;
    investorOtherCityNm: any;
    investorOtherDistrictCd: any;
    investorOtherDistrictNm: any;
    investorOtherUrbanCd: any;
    investorOtherUrbanNm: any;

    investorWorkCountryCode: any;
    investorWorkCountryName: any;
    investorWorkAddress: any;
    investorWorkPrpsCd: any;
    investorWorkPrpsNm: any;
    investorWorkCityCd: any;
    investorWorkCityNm: any;
    investorWorkDistrictCd: any;
    investorWorkDistrictNm: any;
    investorWorkUrbanCd: any;
    investorWorkUrbanNm: any;

    investorPostalCode: any;
    investorOtherPostalCode: any;
    investorOccupation: any;
    investorOccupationDetl: any;
    investorOtherJob: any;
    investorOfficeName: any;
    investorEntpType: any;
    investorWorkPostalCode: any;
    investorWorkHomePhone: any;
    // investorWorkFax: any,
    investorJobRole: any;
    investorWorkYear: any;
    investorWorkMonth: any;
    assetOwner: any;
    investorFundSource: any;
    investorFundSourceDetl: any;
    investorIncomePerAnnum: any;
    iBenfOwnerNm: any;
    iBenfOwnerDob: any;
}

const AccessManagementLivin = (props: any) => {

    const Page = PageConstUtil();
    const MYURL = EndPoint();
    const { height, width } = useWindowDimensions();

    const [whichPage, setPage] = useState(Page.LOGIN_PIN);
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);
    const [getModalTitleMessage, setModalTitleMessage] = useState('');
    const [getModalDescMessage, setModalDescMessage] = useState('');
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [etPIN, setPIN] = useState("");
    const [isOpenModalLoginPIN, setOpenModalLoginPIN] = useState(false);
    const [getCellPhoneNumber, setCellPhoneNumber] = useState('');
    const [getEmail, setEmail] = useState('');
    const [isWni, setWni] = useState(props?.account?.ctznCd == '85');
    const [getBioCache, setBioCache] = useState<StoreBioFormProps>({
        assetOwner: null,

        investorCountryCode: null,
        investorCountryName: null,
        investorAddress: null,
        investorPrpsCd: null,
        investorPrpsNm: null,
        investorCityCd: null,
        investorCityNm: null,
        investorDistrictCd: null,
        investorDistrictNm: null,
        investorUrbanCd: null,
        investorUrbanNm: null,

        investorOtherCountryCode: null,
        investorOtherCountryName: null,
        investorOtherAddress: null,
        investorOtherPrpsCd: null,
        investorOtherPrpsNm: null,
        investorOtherCityCd: null,
        investorOtherCityNm: null,
        investorOtherDistrictCd: null,
        investorOtherDistrictNm: null,
        investorOtherUrbanCd: null,
        investorOtherUrbanNm: null,

        investorWorkCountryCode: null,
        investorWorkCountryName: null,
        investorWorkAddress: null,
        investorWorkPrpsCd: null,
        investorWorkPrpsNm: null,
        investorWorkCityCd: null,
        investorWorkCityNm: null,
        investorWorkDistrictCd: null,
        investorWorkDistrictNm: null,
        investorWorkUrbanCd: null,
        investorWorkUrbanNm: null,

        investorBirthDate: null,
        investorBirthPlace: null,
        investorCidExp: null,
        investorCidNo: null,
        investorCitizenship: null,
        investorCountry: null,
        investorDependencyCount: null,
        investorEducationalBackground: null,
        investorEmail: null,
        // investorFax: null,
        investorFullName: null,
        investorFundSource: null,
        investorFundSourceDetl: null,
        investorHomePhone: null,
        investorIncomePerAnnum: null,
        investorJobRole: null,
        investorOtherJob: null,
        investorKitasExp: null,
        investorKitasNo: null,
        investorMartialStatus: null,
        investorMobilePhone: null,
        investorMothersMaidenName: null,
        investorNPWPNumber: null,
        investorNPWPRegistrationDate: null,
        investorOccupation: null,
        investorOccupationDetl: null,
        investorOfficeName: null,

        investorEntpType: null,
        investorOtherPostalCode: null,
        investorPassExp: null,
        investorPassNo: null,
        investorPostalCode: null,
        investorSeperateAggreement: null,
        investorSex: null,
        investorSpouseBirthDate: null,
        investorSpouseCidNo: null,
        investorSpouseCntcNo: null,
        investorSpouseName: null,

        // investorWorkFax: null,
        investorWorkHomePhone: null,
        investorWorkPostalCode: null,
        investorWorkYear: null,
        investorWorkMonth: null,
        userId: null,
        ypId: [],

        iBenfOwnerNm: null,
        iBenfOwnerDob: null,
    });

    const [getSelectedSeparationAgreement, setSelectedSeparationAgreement] = useState(0);
    //Revamp Main Date
    const [getCidExpMain, setCidExpMain] = useState('');
    const [getPassExpMain, setPassExpMain] = useState(new Date());
    const [getDob, setDob] = useState(new Date());
    const [getSpDob, setSpDob] = useState(new Date());
    const [getNPWPRegisteredAt, setNPWPRegisteredAt] = useState(null);
    const [isHaveHomePhone, setHaveHomePhone] = useState(false);
    const [isOpenModalManagementLivin, setOpenModalManagementLivin] = useState(false);

    interface ListMock {
        readonly value: string;
        readonly label: string;
    }
    interface ListMockUrban {
        readonly value: string;
        readonly label: string;
        readonly postal: string;
    }

    //Address
    var dataCountryList: ListMock[] = [];
    const [getCountryList, setCountryList] = useState([]);
    const [getSelectedCountryCd, setSelectedCountryCd] = useState([]);

    var dataProvinceList: ListMock[] = [];
    const [getProvinceList, setProvinceList] = useState([]);
    const [getSelectedProvinceCd, setSelectedProvinceCd] = useState([]);

    const getDataExpiredList = [
        { label: isWni ? '1 Hari' : '1 Day', value: '86400' },
        { label: isWni ? '1 Minggu' : '1 Week', value: '604800' },
        { label: isWni ? '1 Bulan' : '1 Month', value: '2592000' },
        { label: isWni ? '3 Bulan' : '3 Month', value: '7776000' },
        { label: isWni ? '6 Bulan' : '6 Month', value: '15552000' },
        { label: isWni ? '1 Tahun' : '1 Years', value: '31536000' },
    ];

    const [getDataExpiredSelect, setGetDataExpiredSelect] = useState([
        { label: isWni ? '3 Bulan' : '3 Month', value: '7776000' },
    ]);

    const [getGrantAccessLivin, setGetGrantAccessLivin] = useState(false);

    useEffect(() => {
        if (etPIN.length == 6) {
            handleLoginPIN(etPIN)
        }
    }, [etPIN]);

    useEffect(() => {
        if (whichPage) {
            if (Page.REVAMP_MANAGEMENT_LIVIN) {
                const isTokenValidLivin = Storage.local.get('isTokenValidLivin');
                setGetGrantAccessLivin(isTokenValidLivin);
            }
        }
    }, [whichPage])


    useEffect(() => {
        switch (props.accesslivin.actionType) {
            case ACTION_TYPES.LIVIN_ACCESS_PERMISSION:
                if (props.accesslivin.reqStatus == API_SUCCESS) {
                    if (props.accesslivin.code != 200) {
                        toast.error(props.accesslivin?.message)
                    } else {
                        toast.success(props.accesslivin?.message)
                    }
                } else {
                    console.log('props.accesslivin 3', props.accesslivin)
                }
                break;
        }
    }, [props.accesslivin.actionType, props.accesslivin.reqStatus])


    const handleLoginPIN = (pin: string) => {
        const bodyApi = { userId: Storage.local.get('username'), pin: pin };
        setLoadingButton(true)

        Axios.post(MYURL.VERIFY_PIN,
            bodyApi,
            { headers: { "Content-Type": "application/json" } })
            .then(
                res => {
                    let decryptedMessage = decryptJson(res.data.message);
                    if (decryptedMessage !== "PIN verified") {
                        setPIN("")
                        setLoadingButton(false)
                        setModalIllustrationStatus(false);
                        setOpenModalLoginPIN(true);
                        setModalTitleMessage(LANG().FAILED);
                        setModalDescMessage("" + decryptedMessage);
                    }
                }
            )
            .then(r => {
                handleGetUser()
            })
            .then(r => {
                handleCitizenshipList()
            })
            // .then(r => {
            //     handleNpwpExistingData()
            // })
            .catch(
                res => {
                    if (res.status >= 500) {
                        setLoadingButton(false)
                        setModalIllustrationStatus(false);
                        setOpenModalLoginPIN(true);
                        setModalTitleMessage(LANG().FAILED);
                        setModalDescMessage("Terjadi kesalahan jaringan, mohon coba kembali.");
                    } else {
                        setLoadingButton(false)
                        setModalIllustrationStatus(false);
                        setOpenModalLoginPIN(true);
                        setModalTitleMessage(LANG().FAILED);
                        let decryptedMessage = decryptJson(res.response.data.message);
                        setModalDescMessage("" + decryptedMessage);
                    }
                });
    };

    const handleGetUser = () => {
        const timeStamp = new Date().toISOString();

        const bodyApi = {
            userId: Storage.local.get('username'),
            value: 'all',
            key: MyEncrypt({
                code: MySecKey(),
                timestamp: timeStamp,
                id: Storage.local.get('username'),
            }),
            timestamp: timeStamp,
        };

        Axios.post(MYURL.GET_USER_CONTACT, bodyApi, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (res.status == 200) {
                    if (
                        res.data.email != null &&
                        res.data.email != '' &&
                        res.data.mobile != null &&
                        res.data.mobile
                    ) {
                        setEmail(res.data.email);
                        setCellPhoneNumber(res.data.mobile);
                        handleGetUserBio('first_initial');
                    } else {
                        setLoadingButton(false);
                        setModalIllustrationStatus(false);
                        setModalTitleMessage(LANG().FAILED);
                        setModalDescMessage(LANG().EMPTY_NO_OR_EMAIL);
                        setOpenModalLoginPIN(true);
                    }
                } else {
                    setLoadingButton(false);
                    setModalIllustrationStatus(false);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
                    setOpenModalLoginPIN(true);
                }
            })
            .catch((res) => {
                setLoadingButton(false);
                setModalIllustrationStatus(false);
                setModalTitleMessage(LANG().FAILED);
                setModalDescMessage(LANG().FAILED_RETRIEVE_USER);
                setOpenModalLoginPIN(true);
            });
    };

    const handleCitizenshipList = () => {
        Axios.get(MYURL.CITIZENSHIP_LIST, {
            headers: { 'Content-Type': 'application/json' },
        }).then((res) => {
            // res.data.forEach((it, index) => {
            //     if (index == 0) {
            //         formCardVerification.values.etVerifCardCountryLabel = it.ctznNm;
            //     }
            //     dataCitizenshipList.push({
            //         label: it.ctznNm,
            //         value: it.ctznCd,
            //     });
            // });
            // setCzList(dataCitizenshipList);
        });
    };

    const handleGetUserBio = async (from) => {
        Axios.get(MYURL.CACHED_USER_BIO, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then((res) => {
                if (res.status == 200 && props.account.isUpdateData == '1') {
                    setLoadingButton(false);
                    setPage(Page.REVAMP_MANAGEMENT_LIVIN);
                    // } else if (res.status == 200) {


                } else {
                    setLoadingButton(false);
                    // setOpenModalManagementLivin(true);
                    setModalIllustrationStatus(false);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(res.data.message);
                }
            })
            .catch((res) => {
                setLoadingButton(false);
                if (res.response.status == 400) {
                    setPage(Page.REVAMP_MANAGEMENT_LIVIN);
                } else {
                    // setOpenModalManagementLivin(true);
                    setModalIllustrationStatus(false);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(LANG().DATA_INVALID);
                }
            });
    };

    const refreshUpdatedEditCancel = () => {
        setUpdatedetFUllname(false);
        setUpdatedetKTPNumber(false);
        setUpdatedetKTPExpired(false);
        setUpdatedetDOBPlace(false);
        setUpdateddpDOB(false);
        setUpdatedetKindOfBusiness(false);
        setUpdatedrbGender(false);
        setUpdatedrbMaritalStatus(false);
        setUpdatedetCoupleName(false);
        setUpdatedetCoupleIdentityNumber(false);
        setUpdatedetCoupleDOB(false);
        setUpdatedetCouplePhone(false);
        setUpdatedetMomName(false);
        setUpdatedetNumberofDependents(false);
        setUpdatedrbPropertySeparationAgreement(false);
        setUpdatedetNPWP(false);
        setUpdateWorking(false);
        setUpdateddpNPWPJoinDate(false);
        setUpdatedetHomePhoneNumber(false);
        // setUpdatedetFaxNumber(false)
        setUpdatedetPhoneNumber(false);
        setUpdatedetEmailAddress(false);
        setUpdatedrbLastEducation(false);
        setUpdatedetFullAddress(false);
        setUpdatedetNationallity(false);
        setUpdatedetProvince(false);
        setUpdatedetCity(false);
        setUpdatedetDistrict(false);
        setUpdatedetUrban(false);
        setUpdatedetPostalCode(false);
        setUpdatedetFullAddressDom(false);
        setUpdatedetNationallityDom(false);
        setUpdatedetProvinceDom(false);
        setUpdatedetCityDom(false);
        setUpdatedetDistrictDom(false);
        setUpdatedetUrbanDom(false);
        setUpdatedetPostalCodeDom(false);
        setUpdatedrbDomisilliStatus(false);
        setUpdatedrbOccupation(false);
        setUpdatedetNameOfBusiness(false);
        setUpdatedrbBusinessSector(false);
        setUpdatedetCompanyAddress(false);
        setUpdatedetCompanyCountry(false);
        setUpdatedetCompanyProvince(false);
        setUpdatedetCompanyCity(false);
        setUpdatedetCompanyDistrict(false);
        setUpdatedetCompanyUrban(false);
        setUpdatedetCompanyPostalCode(false);
        setUpdatedetOfficePhoneNumber(false);
        // setUpdatedetCompanyFaxNumber(false)
        setUpdatedetPosition(false);
        setUpdatedetWorkingFor(false);
        setUpdatedrbFundSourceFrom(false);
        setUpdatedrbFundPerMonth(false);
        setUpdatedBailsmanName(false);
        setUpdatedBailsmanDob(false);
    };

    const loginPinLayout = (
        <div className="page-wrapper-pin">
            <Modal style={{ top: '25%' }} isOpen={isOpenModalLoginPIN}>
                <ModalBody>
                    <div className="text-center mb-4">
                        {getModalIllustrationStatus ? (
                            <SuccessIllustration />
                        ) : (
                            <FailedIllustration />
                        )}
                    </div>
                    <p
                        style={{ fontSize: '20px' }}
                        className="text-center mb-2 font-m-500"
                    >
                        {getModalTitleMessage}
                    </p>
                    <p
                        style={{ fontSize: '14px' }}
                        className="text-center mb-2 font-r-400"
                    >
                        {getModalDescMessage}
                    </p>
                    <div
                        className="d-flex justify-content-center"
                        onClick={() => {
                            setOpenModalLoginPIN(false);
                            setModalTitleMessage('');
                            setModalDescMessage('');
                            location.reload();
                        }}
                    >
                        <DarkPurpleButton text="OK" width="20%" />
                    </div>
                </ModalBody>
            </Modal>

            <div
                style={
                    width <= 360
                        ? { marginLeft: 'auto', marginRight: 'auto', width: width }
                        : { marginLeft: 'auto', marginRight: 'auto', width: '330px' }
                }
            >
                <Row className="nav-title">
                    <div className="media-body d-flex justify-content-left">
                        <Link
                            to={{
                                pathname: '/',
                            }}
                        >
                            <div
                                style={{ marginRight: 12 }}
                                className="d-flex align-items-center"
                            >
                                <NavBackIcon />
                            </div>
                        </Link>
                        <div className="font-r-400 text-black">
                            {LANG().TITLE.LOGIN_PIN}
                        </div>
                    </div>
                </Row>

                <Row className="mt-4 pin-width">
                    <OtpInput
                        value={etPIN}
                        onChange={(e) => {
                            setPIN(e);
                        }}
                        numInputs={6}
                        inputType={'password'}
                        renderSeparator={<span style={{ width: '20px' }}></span>}
                        renderInput={(props) => (
                            <input
                                {...props}
                                style={{
                                    width: width <= 320 ? '28px' : width <= 360 ? '34px' : '40px',
                                    height:
                                        width <= 320 ? '28px' : width <= 360 ? '34px' : '40px',
                                    fontSize: '24px',
                                    borderTop: '0px',
                                    borderLeft: '0px',
                                    borderRight: '0px',
                                    outline: 'none',
                                    borderColor: '#E0E0E0',
                                    color: '#000',
                                    textAlign: 'center',
                                }}
                            />
                        )}
                        shouldAutoFocus
                    />

                    <br />
                    <br />

                    <div style={{ marginTop: 40 }}>
                        <DarkPurpleButton
                            width={width <= 360 ? width - 50 : '325px'}
                            text={LANG().BUTTON.SEND}
                            disabled={loadingButton}
                        />
                    </div>
                </Row>
            </div>
        </div>
    );

    const handleChangeGrantAccess = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const usernameKyc = Storage.local.get('username');
        setGetGrantAccessLivin(event.target.checked);
        if (event.target.checked) {
            let postData = { username: usernameKyc, lifespan: 7776000, active: true }
            await props.grantAccessLivinAccessPermission(postData);
            setGetDataExpiredSelect([{ label: isWni ? '3 Bulan' : '3 Month', value: '7776000' }]);
        } else {
            let postData = { username: usernameKyc, active: false }
            await props.grantAccessLivinAccessPermission(postData);
        }
    };

    const handleChangeExpiredToken = async (e) => {
        setGetDataExpiredSelect([{ label: e.label, value: e.value }]);
        const usernameKyc = Storage.local.get('username');
        let postData = { username: usernameKyc, lifespan: e.value, active: true }
        await props.grantAccessLivinAccessPermission(postData);
    }

    const handleRenewToken = async (e) => {
        const usernameKyc = Storage.local.get('username');
        let postData = { username: usernameKyc, lifespan: 7776000, active: true }
        await props.grantAccessLivinAccessPermission(postData);
        setGetDataExpiredSelect([{ label: isWni ? '3 Bulan' : '3 Month', value: '7776000' }]);
    }

    const revampManagementLivin = (
        <div className="page-wrapper-pin">
            <div className="col-md-6 col-lg-6" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Row className="nav-title">
                    <div className="media-body d-flex justify-content-left">
                        <div
                            onClick={() => {
                                window.location.href = '/'
                                // isCacheFilled
                                //     ? (window.location.href = '/')
                                //     : setPage(Page.REVAMP_CARD);
                            }}
                            style={{ marginRight: 12, cursor: 'pointer' }}
                            className="d-flex align-items-center"
                        >
                            <NavBackIcon />
                        </div>
                        <div className="font-m-500">{isWni ? 'Akses Manajemen Livin' : 'Livin Management Access'}</div>
                        <div style={{ display: 'flex', flexGrow: 1 }} />
                        <div className="d-lg-block d-xl-block" />
                    </div>
                </Row>
                <Col>
                    <div className={'mb-3'}>
                        <div className={'mb-2'} style={{ fontSize: '20px', fontWeight: 700 }}>
                            {isWni ? 'Izinkan Livin Mengakses' : 'Allow Livin to Access'}
                        </div>
                        <span className={'mb-2'} style={{ fontSize: '14px', fontWeight: 400 }}>
                            {isWni ?
                                'Dengan ini Anda mengizinkan Livin untuk mengakses data portfolio Anda seperti nilai portfolio, pencairan dana, dan aktivitas top up.'
                                :
                                'By this, you allow Livin to access your portfolio data such as portfolio value, fund withdrawals, and top-up activities.'}

                        </span>
                        <div className={'mb-2'}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        checked={getGrantAccessLivin}
                                        onChange={handleChangeGrantAccess}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } label={isWni ? 'Izinkan' : 'Allow'} />
                            </FormGroup>
                        </div>
                    </div>
                    {getGrantAccessLivin ?
                        <div className={'mb-3'}>
                            <div className={'mb-2'} style={{ fontSize: '20px', fontWeight: 700 }}>
                                {isWni ? 'Atur Masa Kedaluarsa' : 'Set Expiration Time'}
                            </div>
                            <span className={'mb-2'} style={{ fontSize: '14px', fontWeight: 400 }}>
                                {isWni ?
                                    'Silahkan atur masa kedaluarsa untuk livin dapat mengakses data portfolio Anda.'
                                    :
                                    'Please set the expiration time for Livin to access your portfolio data.'
                                }

                            </span>
                            <Row className={'mb-2 mt-2'} style={{ width: '50%' }}>
                                <Select
                                    name={'etPosition'}
                                    className="basic-single"
                                    placeholder={''}
                                    defaultValue={getDataExpiredSelect}
                                    value={getDataExpiredSelect}
                                    options={getDataExpiredList}
                                    isSearchable={false}
                                    styles={{
                                        option: (styles, { isFocused, isSelected }) => ({
                                            ...styles,
                                            border: 0,
                                            background: isFocused
                                                ? '#f8dbc4'
                                                : isSelected
                                                    ? '#F37F26'
                                                    : undefined,
                                            zIndex: 1,
                                        }),
                                    }}
                                    onChange={(e) => {
                                        handleChangeExpiredToken(e);
                                    }}

                                />
                            </Row>
                        </div>
                        : <></>}
                    {getGrantAccessLivin ?
                        <div className={'mb-3'}>
                            <div className={'mb-2'} style={{ fontSize: '20px', fontWeight: 700 }}>
                                {isWni ? 'Perbarui Token' : 'Renew Token'}
                            </div>
                            <span className={'mb-2'} style={{ fontSize: '14px', fontWeight: 400 }}>
                            {isWni ? 
                            'Anda dapat memperbarui akses livin yang telah kedaluarsa dengan menekan tombol di bawah ini.' 
                            : 
                            'You can renew Livin access that has expired by pressing the button below.'
                            }
                                
                            </span>
                            <div className={'mb-2 mt-2'}>
                                <Button
                                    className="py-2 h2 text-white font-weight-bold mt-1 rounded"
                                    style={{ backgroundColor: '#043B72', borderColor: '#043B72' }}
                                    onClick={handleRenewToken}>
                                    {' '}
                                    {/* {loadingButtonResetData ? (
                                        <Spinner
                                            color="#fff"
                                            size="sm"
                                            style={{ marginRight: '8px' }}
                                        />
                                    ) : (
                                        <></>
                                    )}{' '} */}
                                    {isWni ? 'Perbarui Token' : 'Renew Token'}
                                </Button>
                            </div>
                        </div>
                        : <></>}
                </Col>
            </div>
        </div>
    )

    const renderLayout = () => {
        switch (whichPage) {
            case Page.LOGIN_PIN:
                return loginPinLayout;
            case Page.REVAMP_MANAGEMENT_LIVIN:
                return revampManagementLivin;
            default:
                return ""
        }
    };

    return renderLayout();

}
const mapStateToProps = (storeState: IRootState) => ({
    account: storeState.authentication.account,
    accesslivin: storeState.livinState
})

const mapDispatchToProps = {
    grantAccessLivinAccessPermission
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagementLivin);