import React from 'react';
import { Switch } from 'react-router-dom';

import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EmailPhone from 'app/modules/form/email-phone/email-phone';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import NotFound from 'app/modules/not-found';
import ForgotPin from 'app/modules/account/forgot/forgot-pin';
import ForgotUserID from 'app/modules/account/forgot/forgot-userid';
import Log from 'app/modules/log/log';
// import AdminLog from 'app/modules/admin/admin-log';
import { Storage } from 'react-jhipster';
import GeneralSettingConstant from 'app/shared/util/general-settings-constant';
import BankOut from 'app/modules/form/bank-out/bank-out';
import RevampData from 'app/modules/form/revamp-data/revamp-data';
import AccessManagementLivin from 'app/modules/form/accessmanagementlivin/accessmanagementlivin';
import RevampDataReupload from 'app/modules/form/revamp-data/revamp-data-reupload';
import MoveStock from 'app/modules/form/move-stock/move-stock';
import ActivateRtl from 'app/modules/form/activate-rtl/activate-rtl';
import DeactivateRtl from 'app/modules/form/deactivate-rtl/deactivate-rtl';
import TaxReport from 'app/modules/form/tax-report/tax-report';
import IFrameLoading from 'app/modules/form/revamp-data/IFrameLoading';

const Routes = () => (
  <div className="view-routes">
    {Storage.local.get('role') == GeneralSettingConstant().ROLE_ADMIN ? (
      <Switch>
        <ErrorBoundaryRoute path="/log" exact component={Log} />
        <ErrorBoundaryRoute path="/" exact component={Log} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute component={NotFound} />
      </Switch>
    ) : (
      <Switch>
        {/*<ErrorBoundaryRoute path="/bank-out" component={BankOut} />*/}
        {/*<ErrorBoundaryRoute path="/email-phone" component={EmailPhone} />*/}
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/login" component={Login} />
        {/*<ErrorBoundaryRoute path="/forgot-userid" component={ForgotUserID} />*/}
        <ErrorBoundaryRoute path="/forgot-pin" component={ForgotPin} />
        {/*<ErrorBoundaryRoute path="/567DFGHB89VGYHJBHJ89234BHJNBHN" component={RevampData} />*/}
        <ErrorBoundaryRoute path="/revamp-data" component={RevampData} />
        <ErrorBoundaryRoute
          path="/revamp-data-reupload"
          component={RevampDataReupload}
        />
        <ErrorBoundaryRoute path="/access-management-livin" component={AccessManagementLivin} />
        {/*<ErrorBoundaryRoute path="/activate-rtl" component={ActivateRtl} />*/}
        {/*<ErrorBoundaryRoute path="/deactivate-rtl" component={DeactivateRtl} />*/}
        {/*<ErrorBoundaryRoute path="/move-stock" component={MoveStock} />*/}
        <ErrorBoundaryRoute path="/" exact component={Home} />
        {Storage.local.get('MENU_TAX_REPORT') ? (
          <ErrorBoundaryRoute path="/tax-report" exact component={TaxReport} />
        ) : (
          <ErrorBoundaryRoute path="/tax-report" exact component={NotFound} />
        )}
        {/* <ErrorBoundaryRoute path="/tax-report" exact component={TaxReport} /> */}
        <ErrorBoundaryRoute path="/liveness/loading" exact component={IFrameLoading} />
        <ErrorBoundaryRoute component={NotFound} />
      </Switch>
    )}
  </div>
);

export default Routes;
