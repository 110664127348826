import axios from "axios"
import EndPoint from 'app/shared/util/api-constant';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ACTION_TYPES } from "app/shared/reducers/accesspermissionlivin";


/* H5 Liveness */
export const P_LIVIN_ACCESS_PERMISSION = "/services/customerservice/app/internal/api/kc/manage/token"
export const P_LIVIN_ACCESS_CHECK_USER = "/services/customerservice/api/livin/v1/user"


export const grantAccessLivinAccessPermission: (data) => void = (data) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.LIVIN_ACCESS_PERMISSION,
        payload: axios.post(P_LIVIN_ACCESS_PERMISSION, data)
    })  
}

export const grantAccessLivinCheckUser: (data) => void = (data) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.LIVIN_ACCESS_CHECK_USER,
        payload: axios.post(P_LIVIN_ACCESS_CHECK_USER+`?userid=`+data)
    })  
}

// export const livenessDetectionResult: (data) => void = (data) => async (dispatch, getState) => {
//     await dispatch({
//         type: ACTION_TYPES.LIVENESS_DETECTION_RESULT,
//         payload: axios.post(P_LIVENESS_DETECTION_RESULT, data)
//     })
// }

// export const livenessDetectionValidate: (data) => void = (data) => async (dispatch, getState) => {
//     await dispatch({
//         type: ACTION_TYPES.LIVENESS_DETECTION_VALIDATE,
//         payload: axios.post(P_LIVENESS_DETECTION_VALIDATE, data)
//     })
// }

// export const livenessDetectionReset: () => void = () => async(dispatch, getState) => {
//     await dispatch({
//         type: ACTION_TYPES.LIVENESS_DETECTION_RESET,
//         payload: {}
//     })
// }