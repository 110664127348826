import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';
import authUtilState, { AuthUtilState } from './auth-util';
import taxReportState, { TaxReportState } from './tax-report-reducer';

import livenessState, { LivenessState } from './h5-livenes';
import livinState, { LivinState } from './accesspermissionlivin';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly authUtilState: AuthUtilState;
  readonly taxReportState: TaxReportState;
  readonly livenessState: LivenessState;
  readonly livinState: LivinState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  authUtilState,
  taxReportState,
  livenessState,
  livinState
});

export default rootReducer;
